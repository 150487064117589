import type {Nullable, PaymentFrequency, Scale as TScale, Selected as TSelected} from '@nib/types-session-api';
import {DependantValues} from './../components/Forms/FamilyDetails/types';
import {endOfDay, format, isValid, parseISO, isBefore} from 'date-fns';
import {HospitalTiersBadgeModalProps} from '@nib-components/product-card/dist/@types/HospitalTiersBadgeModal';
import {utcToZonedTime} from 'date-fns-tz';
import {ARHIPage} from '../types/pages';
import {ARHIPageIndex, ARHIPageList, ISO_DATE_FORMAT, NIB_HOMEPAGE_URL, QuoteExpiredErrorPageLink} from '../constants';
import {Selected, Scale} from '@nib/phi-constants';
import {globalHistory} from '@reach/router';
import {getCookieSessionId} from './cookies';
import {ProductIds, ProductVariationData} from '../hooks/UseProductIdsStatic';
import {SuggestedProduct} from '../hooks/UseSuggestedProductsStatic';
import {captureMessage} from '@sentry/gatsby';
import {ARHIJoinState} from '../redux/rootReducer';
import {getIsCorporateUser} from '../redux/session/selectors';
import {getHospitalExtrasProductVariation} from '../templates/utils';
import {SummaryOfferTemplate} from '../redux/offer/types';

/* Custom generic type guard. Use when trying to decide if a type is of a particular type / base type */
export const isOfType = <T>(varToBeChecked: unknown, propertyToCheckFor: keyof T): varToBeChecked is T => (varToBeChecked as T)[propertyToCheckFor] !== undefined;

const SydneyOffsetDay = '+10:00';
const SydneyOffsetDayLightSavings = '+11:00';

export const isAestDayLightSavings = (date: Date): boolean => {
  if (!date && isOfType<Date>(date, 'getDate')) return false;

  //Hopefully IE won't be a thing in 2024 and beyond.
  if (date.getFullYear() < 2021 || date.getFullYear() > 2024) return false;

  const utcTestValue = date.getTime();

  //AEST day light saving time converted to UTC via https://www.worldtimebuddy.com/
  const daylightSavingStartDateUtc2020 = Date.UTC(2020, 9, 3, 16, 0, 0);
  const daylightSavingEndDateUtc2021 = Date.UTC(2021, 3, 3, 16, 0, 0);

  const daylightSavingStartDateUtc2021 = Date.UTC(2021, 9, 2, 16, 0, 0);
  const daylightSavingEndDateUtc2022 = Date.UTC(2022, 3, 2, 16, 0, 0);

  const daylightSavingStartDateUtc2022 = Date.UTC(2022, 9, 1, 16, 0, 0);
  const daylightSavingEndDateUtc2023 = Date.UTC(2023, 3, 1, 16, 0, 0);

  const daylightSavingStartDateUtc2023 = Date.UTC(2023, 8, 30, 16, 0, 0);
  const daylightSavingEndDateUtc2024 = Date.UTC(2024, 3, 6, 16, 0, 0);

  const daylightSavingStartDateUtc2024 = Date.UTC(2024, 9, 5, 16, 0, 0);
  const daylightSavingEndDateUtc2025 = Date.UTC(2025, 3, 5, 16, 0, 0);

  if (utcTestValue > daylightSavingStartDateUtc2020 && utcTestValue < daylightSavingEndDateUtc2021) return true;
  if (utcTestValue > daylightSavingStartDateUtc2021 && utcTestValue < daylightSavingEndDateUtc2022) return true;
  if (utcTestValue > daylightSavingStartDateUtc2022 && utcTestValue < daylightSavingEndDateUtc2023) return true;
  if (utcTestValue > daylightSavingStartDateUtc2023 && utcTestValue < daylightSavingEndDateUtc2024) return true;
  if (utcTestValue > daylightSavingStartDateUtc2024 && utcTestValue < daylightSavingEndDateUtc2025) return true;

  return false;
};

export const getAustraliaSydneyOffset = (date: Date): string => {
  return isAestDayLightSavings(date) ? SydneyOffsetDayLightSavings : SydneyOffsetDay;
};

export const utcToZonedTimeE11Safe = (date: Date): Date => {
  return utcToZonedTime(date, getAustraliaSydneyOffset(date));
};

const nibBrand = 'nib';
const seniorsBrand = 'seniors';
const dentalBrand = 'nibP2PWeb';
const corporateBrand = 'corporate';
const guhealth = 'guhealth';

export const getBrand = (): string => process.env.GATSBY_BRAND || nibBrand;

export const getThemeBrand = (): string => {
  switch (getBrand()) {
    case dentalBrand:
    case corporateBrand:
      return nibBrand;
    default:
      return getBrand();
  }
};

export const isNibThemeBrand = (): boolean => {
  return getThemeBrand() === nibBrand;
};

export const isNibBrand = (): boolean => {
  return getBrand() === nibBrand;
};

export const isNibOrDentalBrand = (): boolean => {
  return getBrand() === nibBrand || getBrand() === dentalBrand;
};

export const isSeniorsBrand = (): boolean => {
  return getBrand() === seniorsBrand;
};

export const isDentalBrand = (): boolean => {
  return getBrand() === dentalBrand;
};

export const isCorporateBrand = (): boolean => {
  return getBrand() === corporateBrand;
};

export const isGuHealthBrand = (): boolean => {
  return getBrand() === guhealth;
};

export const FUNNEL_NAME = isNibBrand() ? 'ARHI' : getBrand().toUpperCase();

export const isBoolean = (arg): boolean => {
  return typeof arg === 'boolean';
};

export const isNumber = (arg): boolean => {
  return typeof arg === 'number';
};

export const isString = (arg): boolean => {
  return typeof arg === 'string';
};

export const isFunction = (arg): boolean => {
  return typeof arg === 'function';
};

export const isLocal = (): boolean => process.env.GATSBY_IS_LOCAL?.toUpperCase() === 'TRUE' || false;

export const isValidDateFormat = (date: string): boolean => /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/.test(date);

export const isDateStringWithTimezone = (date: string): boolean => /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T00:00:00[z|Z]?$/.test(date);

export const getItemsFromDate = (date: Nullable<string>, field: string, separator = '-') => {
  if (date === null || !isValidDateFormat(date)) {
    return '';
  }

  if (isDateStringWithTimezone(date)) {
    date = date.substr(0, date.indexOf('T'));
  }

  const [year = '', month = '', day = ''] = date.split(separator);
  const dateObject = {year, month, day};

  return dateObject[field];
};

export const objectHasField = (objectToCheck: object): boolean => {
  if (objectToCheck) {
    return Object.keys(objectToCheck).find((key) => objectToCheck[key] !== null) ? true : false;
  }
  return false;
};

export const dateToApiDateString = (date: Date | string): string => {
  if (date && isOfType<Date>(date, 'getDate')) {
    return format(endOfDay(date), ISO_DATE_FORMAT);
  }
  return format(endOfDay(parseISO(date)), ISO_DATE_FORMAT);
};

export const stringToDate = (dateStr: string): Date => {
  return parseISO(dateStr);
};

export const dateTimeStringToDateString = (dateTimeStr: string): string => {
  if (isDateStringWithTimezone(dateTimeStr)) {
    dateTimeStr = dateTimeStr.substr(0, dateTimeStr.indexOf('T'));
  }
  return dateTimeStr;
};

export const formatDateForDisplay = (dateToFormat: Nullable<string> | undefined, formatToUse = 'dd-MM-yyyy'): string => {
  if (dateToFormat === null || dateToFormat === undefined) return '';

  const dateObject = new Date(dateToFormat);
  if (!isValid(dateObject) || dateToFormat === null) {
    return '';
  }
  const dateFormatted = format(dateObject, formatToUse);
  return dateFormatted;
};

const checkPageAccessSingle = (currentPage: ARHIPage, lastCompletedPage: ARHIPage): boolean => {
  if (currentPage.stepOrder === ARHIPageList[ARHIPageIndex.FAMILY_DETAILS].stepOrder) {
    return false;
  }

  if ((lastCompletedPage.stepOrder || 0) >= ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].stepOrder) {
    return true;
  }

  if (currentPage.stepOrder === ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].stepOrder) {
    return true;
  }

  return false;
};

export const getSessionFailurePath = () => {
  if (isDentalBrand()) return QuoteExpiredErrorPageLink;
  if (isCorporateBrand()) return NIB_HOMEPAGE_URL;
  return ARHIPageList[ARHIPageIndex.WELCOME].link;
};

type SelectedProductIds = {
  hospital: Nullable<number>;
  extras: Nullable<number>;
};

/**
 * Function responsible for checking client permission to visit a certain page and to return the result.
 *
 * @param currentPage
 * @param lastCompletedPage
 * @param scale
 * @param products
 *
 * @returns {Boolean}
 **/
export const checkPageAccess = (currentPage: ARHIPage, lastCompletedPage: ARHIPage, scale: TScale, products: SelectedProductIds, hasSessionError: boolean): boolean => {
  const userLocation = globalHistory.location;
  const cookieSessionId = getCookieSessionId();
  const familyConditionalSteps = [ARHIPageList[ARHIPageIndex.FAMILY_DETAILS].stepOrder, ARHIPageList[ARHIPageIndex.AUSTRALIAN_GOVERNMENT_REBATE].stepOrder];
  const productSteps = [ARHIPageList[ARHIPageIndex.HOSPITAL].stepOrder, ARHIPageList[ARHIPageIndex.EXTRAS].stepOrder];
  const dentalPassEntryPoint = ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].stepOrder;
  const firstProductDependantSteps = [ARHIPageList[ARHIPageIndex.COVER_SUMMARY].stepOrder, ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].stepOrder];
  // type guard
  if (currentPage.stepOrder === null) {
    return false;
  }

  // Dental pass has a different entry point into the funnel
  if (isDentalBrand() && cookieSessionId && currentPage.stepOrder === dentalPassEntryPoint) {
    return true;
  }

  // Must have cookie or 'directQuote' query param true to access product selection pages.
  if (productSteps.includes(currentPage.stepOrder)) {
    if (cookieSessionId) {
      return true;
    } else {
      // We want to allow users to progress past the welcome screen (to product pages) if there is a session error
      if (hasSessionError) {
        return true;
      }
      return userLocation.search.includes('directQuote=true') ? true : false;
    }
  }

  /**
   * Apart from 'directQuote' cases, any page past Welcome must have a cookie.
   */
  if (!cookieSessionId) {
    return false;
  }

  /**
   * Any page past Extras must have a selected product in session be it Hospital or Extras.
   */
  if (currentPage.stepOrder > ARHIPageList[ARHIPageIndex.EXTRAS].stepOrder) {
    if (!products.hospital && !products.extras) {
      return false;
    }
  }

  // Cover and Personal only need a selected product to be accessible.
  if (firstProductDependantSteps.includes(currentPage.stepOrder)) {
    return true;
  }

  // Conditional page access for Single regarding Family and Rebate pages.
  if (familyConditionalSteps.includes(currentPage.stepOrder) && scale === Scale.Single) {
    return checkPageAccessSingle(currentPage, lastCompletedPage);
  }

  // type guard
  if (lastCompletedPage.stepOrder === null) {
    return false;
  }

  // Default condition: can only access the very next step after lastCompletedPage.
  if (currentPage.stepOrder > lastCompletedPage.stepOrder + 1) {
    return false;
  } else {
    return true;
  }
};

export const stringDateIsoDateString = (dateString) => {
  const date = utcToZonedTimeE11Safe(new Date(dateString));
  return format(date, ISO_DATE_FORMAT);
};

export const isStringDateBefore = (dateOne, dateTwo) => {
  return isBefore(endOfDay(stringToDate(dateOne)), endOfDay(stringToDate(dateTwo)));
};

export const ensureDateNotInPast = (dateVal: string | null | undefined): string | null => {
  if (dateVal) {
    const todayAest = utcToZonedTimeE11Safe(new Date());
    if (isBefore(endOfDay(stringToDate(dateVal)), endOfDay(todayAest))) {
      return dateToApiDateString(todayAest);
    }
    return dateVal;
  }
  if (typeof dateVal === 'undefined') dateVal = null;
  return dateVal;
};

export const getMultiplier = (paymentFrequency: PaymentFrequency): number => {
  switch (paymentFrequency) {
    case 'Weekly':
      return 52;
    case 'Fortnightly':
      return 26;
    case 'Monthly':
      return 12;
    case 'Quarterly':
      return 4;
    case 'HalfYearly':
      return 2;
    case 'Yearly':
      return 1;
    default:
      // default to non-zero multiplier
      return 1;
  }
};

/* Convert all key values to a string
 *  Currently, this util function skips null/undefined values
 */
export const stringlyTypeObjectValues = (obj) => {
  const newObj = {};
  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      // We don't want to stringly type null/undefined values
      if (obj[property] !== null && obj[property] !== undefined) {
        // Value is an array, let's stringly type the values within
        if (Array.isArray(obj[property])) {
          newObj[property] = [];
          for (let valueIndex = 0; valueIndex < obj[property].length; valueIndex++) {
            newObj[property][valueIndex] = obj[property][valueIndex] + '';
          }
        }
        // value is an object, let's also stringly type the values within
        else if (typeof obj[property] === 'object') {
          return stringlyTypeObjectValues(obj[property]);
        } else {
          newObj[property] = obj[property] + '';
        }
      }
    }
  }

  return newObj;
};

export const dependantsRequired = (scale: TScale | undefined) => {
  if (typeof scale === 'undefined') return false;

  if (scale === Scale.ExtendedFamily) {
    console.log('Unsupported scale extendedFamily passed to dependantsRequired');
    captureMessage('Unsupported scale extendedFamily passed to dependantsRequired.', 'error');
    return false;
  }

  return scale === Scale.Family || scale === Scale.SingleParentFamily;
};

export const partnerRequired = (scale: TScale | undefined) => {
  if (typeof scale === 'undefined') return false;

  if (scale === Scale.ExtendedFamily) {
    console.log('Unsupported scale extendedFamily passed to partnerRequired.');
    captureMessage('Unsupported scale extendedFamily passed to partnerRequired.', 'error');
    return false;
  }

  return scale === Scale.Couple || scale === Scale.Family;
};

export const isOneAdultScale = (scale: TScale) => {
  return scale === Scale.Single || scale === Scale.SingleParentFamily;
};

export const showDependants = (hasDependants: TSelected | string | null, dependants: DependantValues[], scale: TScale): boolean => {
  return (hasDependants && hasDependants === Selected.yes && dependants && dependants.length && dependantsRequired(scale)) || false;
};

export const showPartner = (hasPartner: TSelected | string | null, scale: TScale): boolean => {
  return (hasPartner && hasPartner === Selected.yes && partnerRequired(scale)) || false;
};

interface HospitalProductPartial {
  tierName: string;
  productTierPlus: boolean;
}

// Generate tier name for modal
export const tierNameProper = (product: HospitalProductPartial) =>
  `${product.tierName ? product.tierName.toLowerCase() : ''}${product.productTierPlus ? '-plus' : ''}` as HospitalTiersBadgeModalProps['tier'];

export const stripDecimal = (str: string) => {
  return str ? str.replace(/\.0$/, '') : '';
};

export const stripDrFromTitle = (title): string => {
  if (title && title === 'Dr') {
    return '';
  }
  return title;
};

export const capitalizeFirstCharacter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const scrollToHTMLElement = (element: string | HTMLElement) => {
  const domElement: HTMLElement | null = typeof element === 'string' ? document.getElementById(element) : element;

  if (domElement) {
    domElement.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
};

export const toggleOffAndScroll = (toggleOff: () => void, elementId: string): void => {
  toggleOff();
  scrollToHTMLElement(elementId);
};

export type ProductType = 'hospital' | 'extras';

export const getVariationProducts = (staticProducts: ProductVariationData, variationId: string, productType: ProductType): number[] => {
  const variation = staticProducts.productVariations.filter((variation) => variation.variationId === variationId);
  if (variation.length <= 0) return [];

  return variation[0][productType];
};

/**
 * We aim to fetch static product ids but only serve the products available for the current variant ?v=(a|b|c|d) in play at runtime.
 * @returns ProductIds
 */
export const useExtrasVariants = (staticProducts: ProductVariationData, productType: ProductType, state: ARHIJoinState): ProductIds => {
  const filtered: ProductIds = {products: [], type: 'ProductIds'};

  if (isNibBrand()) {
    // Divert to corporate-supporting products if user is corporate
    if (getIsCorporateUser(state)) {
      filtered.products = getVariationProducts(staticProducts, 'corporates', productType);
      return filtered;
    }

    // look for query string-driven experiment variation if present
    const applicableVariant = getHospitalExtrasProductVariation(staticProducts.productVariations, productType);
    if (applicableVariant) {
      filtered.products = applicableVariant;
      return filtered;
    }
  }

  // otherwise fallback to the standard set of products for the product type
  filtered.products = productType === 'hospital' ? staticProducts.hospitalProductIds : staticProducts.extrasProductIds;
  return filtered;
};

/**
 * Get suggested product from brand config static data or uses most affordable products for a variant
 */
export const useSuggestedVariants = (nodes, staticSuggestedProduct?: SuggestedProduct): SuggestedProduct | undefined => {
  // TODO: temporary disabled due to conflict in new experiment
  // if (isNibBrand()) {
  //   if (nodes.find((node) => node.pcatId === affordableProductId)) {
  //     return {
  //       productId: affordableProductId
  //     };
  //   }
  // }
  return staticSuggestedProduct;
};

export const shouldShowOffer = (
  useV3CampaignOffer: boolean | undefined,
  offerIdV3: string | undefined,
  extrasProductId: Nullable<number>,
  hospitalProductId: Nullable<number>,
  hasNibPreviousFundCode: boolean,
  offerId: string | undefined,
  template: SummaryOfferTemplate | undefined
): boolean => {
  const hasBothProductsAndNotNibFundCode = extrasProductId && hospitalProductId && !hasNibPreviousFundCode;
  if (useV3CampaignOffer) {
    return !!offerIdV3;
  } else {
    return !!(offerId && template && hasBothProductsAndNotNibFundCode);
  }
};
