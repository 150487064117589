import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../types';
import {OfferState, Offer, OfferParams} from './types';

export const initialState: OfferState = {
  loadingOffer: false,
  fetchedOffer: false,
  submittingOffer: false,
  offer: undefined,
  offersError: null
};

const offerSliceV3 = createSlice({
  name: 'offerV3',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchOfferV3Pending: (state, action: PayloadAction<OfferParams>) => {
      state.loadingOffer = true;
      state.fetchedOffer = false;
    },
    fetchOfferV3Success: (state, action: PayloadAction<Offer>) => {
      state.fetchedOffer = true;
      state.loadingOffer = false;
      state.offer = action.payload;
      state.offersError = null;
    },
    fetchOfferV3Rejected: (state, action: PayloadAction<FailedRequestPayload | any>) => {
      state.fetchedOffer = false;
      state.loadingOffer = false;
      state.offersError = action.payload;
    }
  }
});

export const {fetchOfferV3Pending, fetchOfferV3Success, fetchOfferV3Rejected} = offerSliceV3.actions;

export default offerSliceV3.reducer;
