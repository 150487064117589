import Cookies from 'js-cookie';
import {captureMessage} from '@sentry/gatsby';
import axios from 'axios';
import {stringify} from 'qs';
import {PriceResult} from './pricingSlice';
import {AxiosResponse, AxiosError} from '../types';
import {ValidJoinServicesRequest, createAxiosError} from '../utils';
import {PricingURL} from '../../constants';
import {BatchPriceResults, PricingParams} from './types';
import {isDateStringGreaterThan, isDateStringInFuture} from './utils';
import {effectiveProductDateOverride} from '../../constants';
import {mapCorporateRates} from './mappers';
import {getMockCurrentDate} from '../../utils';

export const fetch = async (parameters: PricingParams): Promise<AxiosResponse<PriceResult> | AxiosResponse<AxiosError>> => {
  try {
    const params = mapCorporateRates(parameters);

    if (effectiveProductDateOverride && isDateStringInFuture(effectiveProductDateOverride)) {
      params.effectiveDate = effectiveProductDateOverride;
    }

    const mockedCurrentDate = getMockCurrentDate();
    if (mockedCurrentDate) {
      //don't override effective date when its greater than mockCurrentDate (eg when its set to ppc Date for future prices or a user selected coverStartDate)
      params.effectiveDate = isDateStringGreaterThan(mockedCurrentDate, parameters.effectiveDate) ? mockedCurrentDate : parameters.effectiveDate;
    }

    const queryParams = stringify(params, {
      skipNulls: true
    });

    const cookie = Cookies.get('use-core-pricing');

    const res = await axios.get(`${PricingURL}?${queryParams}`, {
      validateStatus: ValidJoinServicesRequest,
      headers: {
        'x-use-core-pricing': cookie === 'true',
        'x-mock-current-date': mockedCurrentDate
      }
    });

    return {
      data: res.data.data,
      type: 'Price'
    };
  } catch (error) {
    captureMessage(`Price fetch error -- ${JSON.stringify(error)}`, 'error');
    return {
      type: 'AxiosError',
      data: createAxiosError(error, 'GET')
    };
  }
};

export const batchFetchPrices = async (pricingParams): Promise<AxiosResponse<BatchPriceResults> | AxiosResponse<AxiosError>> => {
  const cookie = Cookies.get('use-core-pricing');

  const {productType, products, ...rest} = pricingParams;
  const params = mapCorporateRates(rest);
  if (effectiveProductDateOverride && isDateStringInFuture(effectiveProductDateOverride)) {
    params.effectiveDate = effectiveProductDateOverride;
  }

  const mockedCurrentDate = getMockCurrentDate();
  if (mockedCurrentDate) {
    //don't override effective date when its greater than mockCurrentDate (eg when its set to ppc Date for future prices or a user selected coverStartDate)
    params.effectiveDate = isDateStringGreaterThan(mockedCurrentDate, pricingParams.effectiveDate) ? mockedCurrentDate : pricingParams.effectiveDate;
  }

  // Pricing API expects an array of products of the shape [ { hospitalProduct: 123 }, ... { extrasProduct: 191 } ]
  // have to map to conform to this
  const productPropertyKey = `${productType as string}Product`;
  const mappedProducts = products.map((p) => ({[productPropertyKey]: p}));

  const pricingFactors = stringify({...params, products: mappedProducts}, {skipNulls: true, encode: false});
  const res = await axios.get(`${PricingURL}?${pricingFactors}`, {
    headers: {
      'x-use-core-pricing': cookie === 'true',
      'x-mock-current-date': mockedCurrentDate
    }
  });
  return {
    data: res.data,
    type: 'Price'
  };
};
