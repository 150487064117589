import {captureMessage} from '@sentry/gatsby';
import * as api from './api';
import {ARHIJoinState} from './../rootReducer';
import {fetchOfferPending, fetchOfferRejected, fetchOfferSuccess} from './offerSlice';
import {AxiosResponse, AxiosError, isAxiosError} from '../types';
import {Offer, OfferParams, OfferResult, PromoOfferResult} from './types';
import {mapStoreToOfferRequest} from './mappers';
import {saveOfferDetails} from '../session/thunks';
import {getCookieSessionId} from '../../services/cookies';
import {getCampaignParameters} from '../session/selectors/offerDetails';

export const fetchOffer = () => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = arhiJoinState.session.session;
  const sessionCookie = getCookieSessionId();

  if (sessionCookie && reduxSession) {
    try {
      // use session to retrieve the latest params needed for the offer
      const offerParams: OfferParams = mapStoreToOfferRequest(arhiJoinState);

      dispatch(fetchOfferPending(offerParams));
      const offerResult: AxiosResponse<OfferResult> | AxiosResponse<AxiosError> = await api.fetch(offerParams);
      if (isAxiosError(offerResult)) {
        // The offerResult will most likely contain a non-serializable object containing functions,
        // so the following trick will exclude all non-serializable attributes
        const serialised = JSON.parse(JSON.stringify(offerResult));
        dispatch(fetchOfferRejected({error: serialised.data}));
      } else {
        const offerWithParams: Offer = {
          ...offerResult.data.offer,
          campaignParameters: {
            utm_campaign: offerParams.utm_campaign || '',
            utm_content: offerParams.utm_content || '',
            utm_source: offerParams.utm_source || ''
          }
        };

        dispatch(
          fetchOfferSuccess({
            offer: offerWithParams
          })
        );

        return {
          data: {
            offer: offerWithParams
          },
          type: 'Offer'
        };
      }
      return offerResult;
    } catch (exception) {
      dispatch(fetchOfferRejected({error: exception}));
    }
  } else {
    const error = 'Error retrieving offer - invalid store or session cookie.';
    captureMessage(error, 'error');
    dispatch(fetchOfferRejected({error: error}));
  }
  return;
};

// fetchOffer also uses promocode - so no need for two different endpoints for promocode vs no promocode + default offer
export const fetchAndSaveOffer = () => async (dispatch, getState) => {
  try {
    const offerResponse = (await dispatch(fetchOffer())) as AxiosResponse<OfferResult> | AxiosResponse<AxiosError> | undefined;
    if (offerResponse) {
      if (!isAxiosError(offerResponse)) {
        // We have an offer returned based on session data in fetchOffer
        await dispatch(
          saveOfferDetails({
            offerId: offerResponse.data.offer.offerId ? parseInt(offerResponse.data.offer.offerId) : null,
            campaignDescription: offerResponse.data.offer.description || null,
            whicsCampaignCode: null,
            welcomeEmailOfferTemplate: null,
            campaignParameters: offerResponse.data.offer.campaignParameters || null,
            // TODO: promoCode is here for type sake
            promoCode: null
          })
        );
      } else if (isAxiosError(offerResponse) && offerResponse.data.response.status === 404) {
        // status 404 means no offer found -- reset offer related data
        const campaignParameters = getCampaignParameters(getState());
        await dispatch(
          saveOfferDetails({
            campaignDescription: null,
            offerId: null,
            welcomeEmailOfferTemplate: null,
            whicsCampaignCode: null,
            campaignParameters,
            // TODO: promoCode is here for type sake
            promoCode: null
          })
        );
      }
      return offerResponse;
    } else {
      const error = `Error retrieving and saving offer -- ${offerResponse}`;
      captureMessage(error, 'error');
      dispatch(fetchOfferRejected({error: error}));
    }
  } catch (exception) {
    dispatch(fetchOfferRejected({error: exception}));
    const error = 'Error retrieving and saving offer to session';
    captureMessage(error, 'error');
  }
  return;
};

export type FetchOfferThunk = () => Promise<AxiosResponse<OfferResult> | AxiosResponse<AxiosError> | undefined>;
export type fetchAndSaveOffer = () => Promise<AxiosResponse<PromoOfferResult> | AxiosResponse<AxiosError> | undefined>;
