import {dateToApiDateString, dependantsRequired, partnerRequired} from './../../../services/utils';
import type {ContactDetails, PhoneNumber, Session, State} from '@nib/types-session-api';
import {Selected} from '@nib/phi-constants';
import {produce} from 'immer';
import {WelcomeDetailsValues} from '../../../components/Forms/Welcome/types';
import {ARHIPageIndex, ARHIPageList} from '../../../constants';
import {getPhoneNumberType, getExtendedScale} from '../../../utils';

export const mapWelcomeDetailsToSession = (values: WelcomeDetailsValues, session: Session, isPageComplete?: boolean, state?: State, excess?: number | undefined): Session => {
  const mappedSession = produce(session, (draftSession) => {
    draftSession.personalDetails.policyHolder.firstName = values.firstName;
    draftSession.personalDetails.scale = getExtendedScale(values.scale, session.personalDetails.dependants);

    // If dob is empty it be should be defaulted so that pricing observer can watch values correctly.
    draftSession.personalDetails.policyHolder.dateOfBirth = values.dateOfBirth || null;

    const hasPartner = partnerRequired(values.scale);
    const hasDependants = dependantsRequired(values.scale);

    draftSession.personalDetails.hasPartner = hasPartner ? Selected.yes : Selected.no;
    draftSession.personalDetails.hasDependants = hasDependants ? Selected.yes : Selected.no;

    if (hasPartner && draftSession.personalDetails.partner) {
      draftSession.personalDetails.partner.dateOfBirth = values.partnerDateOfBirth || null;
    }

    // Declare phone number partial
    const phoneNumbers: PhoneNumber[] = [];
    if (values.phoneNumber) {
      phoneNumbers.push({
        type: getPhoneNumberType(values.phoneNumber),
        number: values.phoneNumber
      });
    }
    // Conditional field that is only possible to set if outside-business-hours experiment
    // is enabled
    if (values.email) {
      draftSession.personalDetails.policyHolder.email = values.email || null;
    }

    // Connect partials to contact details object
    if (draftSession.contactDetails) {
      draftSession.contactDetails.phoneNumbers = phoneNumbers;
    } else {
      const contactDetails: ContactDetails = {
        phoneNumbers: phoneNumbers
      };
      draftSession.contactDetails = contactDetails;
    }

    // Should update funnelProgress for welcome page only when submitting the first time.
    // If stepOrder > 0, then the user has already submitted data for a further page.
    if (isPageComplete && draftSession.funnelProgress && !draftSession.funnelProgress.lastCompletedPage.stepOrder) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.WELCOME];
      if (draftSession.aboutYouDetails) {
        draftSession.aboutYouDetails.hasCompletedAboutYou = true;
      }
    }

    if (state) {
      if (draftSession.contactDetails.address) draftSession.contactDetails.address.state = state;
      else {
        draftSession.contactDetails.address = {
          state: state
        };
      }
    }

    if (excess) {
      draftSession.productSelection.hospital.excess = excess;
    }

    // Cover start date now also conditionally exists the welcome page during PPC period.
    // If we are in the PrePPC period, it exists on the values object. Otherwise we omit it as part of form initialization.
    if (values.coverStartDate) {
      draftSession.financialDetails.coverStartDate.date = values.coverStartDate instanceof Date ? dateToApiDateString(values.coverStartDate) : null;
    }

    if (draftSession.aboutYouDetails) {
      draftSession.aboutYouDetails.allowLeadsContact = values.contact;
      // visitor type also conditionally exists on the welcome page - We only have this activated for the nib funnel
      draftSession.aboutYouDetails.visitorType = values.visitorType || null;
    } else {
      draftSession.aboutYouDetails = {
        visitorType: values.visitorType || null,
        extrasCoverLevel: null,
        hasCompletedAboutYou: false,
        hospitalCoverLevel: null,
        allowLeadsContact: values.contact
      };
    }
  });

  return mappedSession;
};
