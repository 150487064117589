export const FORM_PREFIX = 'pricing-factors' as const;

export const FIELD_NAMES = {
  SCALE: `${FORM_PREFIX}-scale`,
  DATE_OF_BIRTH: `${FORM_PREFIX}-dateOfBirth`,
  DATE_OF_BIRTH_DAY: `${FORM_PREFIX}-dateOfBirthDay`,
  DATE_OF_BIRTH_YEAR: `${FORM_PREFIX}-dateOfBirthYear`,
  DATE_OF_BIRTH_MONTH: `${FORM_PREFIX}-dateOfBirthMonth`,
  PARTNER_DATE_OF_BIRTH: `${FORM_PREFIX}-partnerDateOfBirth`,
  PARTNER_DATE_OF_BIRTH_DAY: `${FORM_PREFIX}-partnerDateOfBirthDay`,
  PARTNER_DATE_OF_BIRTH_YEAR: `${FORM_PREFIX}-partnerDateOfBirthYear`,
  PARTNER_DATE_OF_BIRTH_MONTH: `${FORM_PREFIX}-partnerDateOfBirthMonth`,
  INCOME_TIER: `${FORM_PREFIX}-incomeTier`,
  STATE: `${FORM_PREFIX}-state`,
  TRANSFERING_FUNDS: `${FORM_PREFIX}-transferringFunds`,
  PARTNER_TRANSFERING_FUNDS: `${FORM_PREFIX}-partnerTransferringFunds`,
  PAYMENT_METHOD: `${FORM_PREFIX}-paymentMethod`
} as const;
