import type {Session, Nullable} from '@nib/types-session-api';

import {AxiosError} from './../types';
import {createSlice} from '@reduxjs/toolkit';
import CommonSessionReducers from './reducers/common';
import {saveRibbonDetailsSuccessReducer, saveRibbonDetailsRejectedReducer, saveRibbonDetailsPendingReducer} from './reducers/ribbonDetails';
import {savePersonalDetailsFormSuccessReducer, savePersonalDetailsFormRejectedReducer, savePersonalDetailsFormPendingReducer} from './reducers/personalDetails';
import {saveRebateDetailsFormSuccessReducer, saveRebateDetailsFormRejectedReducer, saveRebateDetailsFormPendingReducer} from './reducers/rebateDetails';
import {saveFamilyDetailsFormPendingReducer, saveFamilyDetailsFormRejectedReducer, saveFamilyDetailsFormSuccessReducer} from './reducers/familyDetails';
import {savePaymentDetailsFormPendingReducer, savePaymentDetailsFormSuccessReducer, savePaymentDetailsFormRejectedReducer} from './reducers/paymentDetails';
import {saveOfferDetailsPendingReducer, saveOfferDetailsSuccessReducer, saveOfferDetailsRejectedReducer} from './reducers/offerDetails';
import {
  saveFunnelProgressPendingReducer,
  saveFunnelProgressRejectedReducer,
  saveFunnelProgressSuccessReducer,
  completeFunnelProgressReducer,
  completeQuoteFunnelProgressReducer
} from './reducers/funnelProgress';
import {saveWelcomeFormPendingReducer, saveWelcomeFormRejectedReducer, saveWelcomeFormSuccessReducer, completeAboutYouReducer} from './reducers/welcomeDetails';
import {saveHospitalDetailsPendingReducer, saveHospitalDetailsRejectedReducer, saveHospitalDetailsSuccessReducer} from './reducers/hospitalDetails';
import {saveExtrasDetailsPendingReducer, saveExtrasDetailsRejectedReducer, saveExtrasDetailsSuccessReducer} from './reducers/extrasDetails';
import {saveSendQuoteDetailsSuccessReducer, saveSendQuoteFormPendingReducer, saveSendQuoteFormRejectedReducer} from './reducers/sendQuoteDetails';
import {saveAttributionDetailsPendingReducer, saveAttributionDetailsRejectedReducer, saveAttributionDetailsSuccessReducer} from './reducers/attributionDetails';
import {saveIncomeDetailsSuccessReducer, saveIncomeFormPendingReducer, saveIncomeFormRejectedReducer} from './reducers/incomeDetails';
import {savePricingFactorsModalPendingReducer, savePricingFactorsModalRejectedReducer, savePricingFactorsModalSuccessReducer} from './reducers/pricingFactorsModalDetails';

export interface SessionStatusState {
  loadingSession?: boolean;
  fetchedSession?: boolean;
  updatingSession?: boolean;
  sessionError?: AxiosError | null;
}

export interface SessionState extends SessionStatusState {
  session?: Session;
}

export const initialState: SessionState = {
  session: undefined,
  loadingSession: true,
  fetchedSession: false,
  updatingSession: false,
  sessionError: null
};

export interface UpdateSessionPayload {
  sessionId: string;
  session: Session;
}

export interface SessionQueryParams {
  directQuote?: boolean;
  id?: Nullable<string>;
  hospitalProductId?: string;
  extrasProductId?: string;
  scale?: string;
  excess?: string;
  state?: string;
  fundId?: string;
  joinSource?: string;
  sourceCode?: string;
  campaignChannel?: string;
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    initSessionPending: CommonSessionReducers.getSessionPending,
    initSessionSuccess: CommonSessionReducers.getSessionSuccess,
    initSessionRejected: CommonSessionReducers.getSessionRejected,
    fetchSessionPending: CommonSessionReducers.getSessionPending,
    fetchSessionSuccess: CommonSessionReducers.getSessionSuccess,
    fetchSessionRejected: CommonSessionReducers.getSessionRejected,
    saveRibbonDetailsPending: saveRibbonDetailsPendingReducer,
    saveRibbonDetailsSuccess: saveRibbonDetailsSuccessReducer,
    saveRibbonDetailsRejected: saveRibbonDetailsRejectedReducer,
    saveHospitalDetailsPending: saveHospitalDetailsPendingReducer,
    saveHospitalDetailsSuccess: saveHospitalDetailsSuccessReducer,
    saveHospitalDetailsRejected: saveHospitalDetailsRejectedReducer,
    saveExtrasDetailsPending: saveExtrasDetailsPendingReducer,
    saveExtrasDetailsSuccess: saveExtrasDetailsSuccessReducer,
    saveExtrasDetailsRejected: saveExtrasDetailsRejectedReducer,
    savePersonalDetailsFormPending: savePersonalDetailsFormPendingReducer,
    savePersonalDetailsFormSuccess: savePersonalDetailsFormSuccessReducer,
    savePersonalDetailsFormRejected: savePersonalDetailsFormRejectedReducer,
    saveRebateDetailsFormPending: saveRebateDetailsFormPendingReducer,
    saveRebateDetailsFormSuccess: saveRebateDetailsFormSuccessReducer,
    saveRebateDetailsFormRejected: saveRebateDetailsFormRejectedReducer,
    saveFamilyDetailsFormPending: saveFamilyDetailsFormPendingReducer,
    saveFamilyDetailsFormSuccess: saveFamilyDetailsFormSuccessReducer,
    saveFamilyDetailsFormRejected: saveFamilyDetailsFormRejectedReducer,
    savePaymentDetailsFormPending: savePaymentDetailsFormPendingReducer,
    savePaymentDetailsFormSuccess: savePaymentDetailsFormSuccessReducer,
    savePaymentDetailsFormRejected: savePaymentDetailsFormRejectedReducer,
    saveOfferDetailsPending: saveOfferDetailsPendingReducer,
    saveOfferDetailsSuccess: saveOfferDetailsSuccessReducer,
    saveOfferDetailsRejected: saveOfferDetailsRejectedReducer,
    saveFunnelProgressPending: saveFunnelProgressPendingReducer,
    saveFunnelProgressRejected: saveFunnelProgressRejectedReducer,
    saveFunnelProgressSuccess: saveFunnelProgressSuccessReducer,
    completeFunnelProgress: completeFunnelProgressReducer,
    completeQuoteFunnelProgress: completeQuoteFunnelProgressReducer,
    saveWelcomeFormPending: saveWelcomeFormPendingReducer,
    saveWelcomeFormRejected: saveWelcomeFormRejectedReducer,
    saveWelcomeFormSuccess: saveWelcomeFormSuccessReducer,
    completeAboutYou: completeAboutYouReducer,
    saveSendQuoteFormPending: saveSendQuoteFormPendingReducer,
    saveSendQuoteFormRejected: saveSendQuoteFormRejectedReducer,
    saveSendQuoteFormSuccess: saveSendQuoteDetailsSuccessReducer,
    saveAttributionDetailsPending: saveAttributionDetailsPendingReducer,
    saveAttributionDetailsSuccess: saveAttributionDetailsSuccessReducer,
    saveAttributionDetailsRejected: saveAttributionDetailsRejectedReducer,
    saveIncomeFormPending: saveIncomeFormPendingReducer,
    saveIncomeFormRejected: saveIncomeFormRejectedReducer,
    saveIncomeFormSuccess: saveIncomeDetailsSuccessReducer,
    savePricingFactorsModalPending: savePricingFactorsModalPendingReducer,
    savePricingFactorsModalRejected: savePricingFactorsModalRejectedReducer,
    savePricingFactorsModalSuccess: savePricingFactorsModalSuccessReducer
  }
});

export const {
  initSessionPending,
  initSessionSuccess,
  initSessionRejected,
  fetchSessionPending,
  fetchSessionSuccess,
  fetchSessionRejected,
  saveRibbonDetailsPending,
  saveRibbonDetailsRejected,
  saveRibbonDetailsSuccess,
  saveHospitalDetailsPending,
  saveHospitalDetailsRejected,
  saveHospitalDetailsSuccess,
  saveExtrasDetailsPending,
  saveExtrasDetailsRejected,
  saveExtrasDetailsSuccess,
  savePersonalDetailsFormPending,
  savePersonalDetailsFormRejected,
  savePersonalDetailsFormSuccess,
  saveRebateDetailsFormPending,
  saveRebateDetailsFormRejected,
  saveRebateDetailsFormSuccess,
  saveFamilyDetailsFormPending,
  saveFamilyDetailsFormSuccess,
  saveFamilyDetailsFormRejected,
  savePaymentDetailsFormPending,
  savePaymentDetailsFormSuccess,
  savePaymentDetailsFormRejected,
  saveOfferDetailsPending,
  saveOfferDetailsRejected,
  saveOfferDetailsSuccess,
  saveFunnelProgressPending,
  saveFunnelProgressRejected,
  saveFunnelProgressSuccess,
  completeFunnelProgress,
  saveWelcomeFormPending,
  saveWelcomeFormRejected,
  saveWelcomeFormSuccess,
  completeAboutYou,
  saveSendQuoteFormPending,
  saveSendQuoteFormRejected,
  saveSendQuoteFormSuccess,
  completeQuoteFunnelProgress,
  saveAttributionDetailsPending,
  saveAttributionDetailsRejected,
  saveAttributionDetailsSuccess,
  saveIncomeFormPending,
  saveIncomeFormRejected,
  saveIncomeFormSuccess,
  savePricingFactorsModalPending,
  savePricingFactorsModalRejected,
  savePricingFactorsModalSuccess
} = sessionSlice.actions;

export default sessionSlice.reducer;
