import {PaymentMethodGroupProps} from './types';
import {saveFormOnChange} from './eventHandlers';
import {isGuHealthBrand, utcToZonedTimeE11Safe} from '../../../services/utils';
import {getCurrentDate} from '../../../utils';

export const FORM_PREFIX = 'payment-details';
export const TEALIUM_FORM_NAME = 'arhi_join_payment_details';

export const FIELD_HELP_TEXT = {
  DATE: 'DD/MM/YYYY'
};

export const FIELD_NAMES = {
  COVER_START_DATE: 'coverStartDate',
  PAYMENT_FREQUENCY: 'paymentFrequency',
  PAYMENT_START_DATE: 'paymentStartDate',
  FUND_RULES_JOIN_AGREEMENT: 'fundRulesJoinAgreement',
  PAYMENT_METHOD: 'paymentMethod',
  BSB: 'bsb'
} as const;

export const FIELDS_TO_IGNORE_ON_SESSION_SAVE = ['fundRulesJoinAgreement', 'creditCardAgreement', 'bsb', 'accountNumber', 'accountName', FIELD_NAMES.FUND_RULES_JOIN_AGREEMENT];

const getBraintreeProps = (props) => {
  const {braintreeError, braintreeInstance, handleBraintreeTeardown, braintreeResponse, handleBraintreePaymentOptionSelected, braintreeBrandOptions, braintreeClientToken, renderErrorNotification} =
    props;
  return {
    errorMessage: braintreeError,
    braintreeDropInInstanceRef: braintreeInstance,
    options: braintreeBrandOptions,
    onPostTeardown: handleBraintreeTeardown,
    braintreeSubmissionError: (braintreeResponse && braintreeResponse.error) || false,
    onPaymentOptionSelected: handleBraintreePaymentOptionSelected,
    braintreeClientToken,
    renderErrorNotification
  };
};

export const getPaymentMethodGroupProps = (formikBag, updateSession, newPrice, braintreeConfigProps, isCorporateCustomer, isCustomRate, bankName): PaymentMethodGroupProps => {
  const canShowDirectDebitDiscount = !(isCustomRate || isCorporateCustomer || isGuHealthBrand());
  const braintreeProps = getBraintreeProps(braintreeConfigProps);
  return {
    defaultMethod: 'DirectDebit',
    newPrice,
    values: {
      paymentMethod: formikBag.values.paymentMethod,
      creditCardAgreement: formikBag.values.creditCardAgreement,
      directDebitAgreement: formikBag.values.directDebitAgreement
    },
    groupOnChangeHandlers: {
      paymentMethod: saveFormOnChange(formikBag, updateSession)
    },
    // Passing in undefined for braintree will continue to use the old CC implementation.
    // See https://github.com/nib-group/design-system/tree/master/packages/form-fields/src/components/payment-method-group
    braintree: braintreeProps,
    paymentMethodCopyContent: braintreeConfigProps.paymentMethodCopyContent,
    showDirectDebitDiscountMessage: canShowDirectDebitDiscount,
    bankName: bankName
  };
};

export const todayAest = utcToZonedTimeE11Safe(getCurrentDate());

export const PROMO_ERROR_MESSAGE = 'The code you entered is not valid or has expired. Please check it and try again.';
export const ERROR_MESSAGE_YOWCH = "Yowch! Really? We're going to assume that was an accident and give you our current offer anyway.";

export const FIELDS_BELOW_BRAINTREE = ['creditCardAgreement', FIELD_NAMES.FUND_RULES_JOIN_AGREEMENT];
export const BSB_NOT_FOUND_MESSAGE = 'BSB not found';
export const BSB_VALIDATION_MESSAGE = 'Your financial institution cannot be found. Please check your BSB is correct.';
