import type {Scale as TScale, Selected as TSelected, PhoneNumberType as TPHoneNumberType} from '@nib/types-session-api';
import {Selected, PhoneNumberType, Scale} from '@nib/phi-constants';
import {isValid as isValidDate, parseISO, differenceInYears} from 'date-fns';
import {MIN_ADULT_DEPENDANT_AGE, MOCK_CURRENT_DATE_COOKIE, mockCurrentDateEnv} from './constants';
import {captureMessage} from '@sentry/gatsby';
import {DEPENDANT_MAX_AGE} from './components/Forms/FamilyDetails/constants';
import Cookies from 'js-cookie';
import {isDateStringInFuture} from './redux/pricing/utils';

export const isNullOrUndefined = (input: any): boolean => {
  if (typeof input === 'undefined' || input === null) return true;

  return false;
};

// MM/YYYY to YYYY-MM
export const formatDateToISO = (expiryDate: string): string => {
  let formattedDate = '';
  const month = expiryDate.substring(0, 2);
  const year = expiryDate.substring(3, 7);

  if (month.length === 2 && year.length === 4) {
    formattedDate = `${year}-${month}`;
  }
  return formattedDate;
};

// YYYY-MM to MM/YYYY
export const formatDateFromISO = (expiryDate: string): string => {
  let formattedDate = '';
  const year = expiryDate.substring(0, 4);
  const month = expiryDate.substring(5, 7);

  if (month.length === 2 && year.length === 4) {
    formattedDate = `${month}/${year}`;
  }
  return formattedDate;
};

// Check if in format yyyy/mm or yyyy-mm
export const isIsoDateStringFormat = (dateString: string): boolean => /^(\d{4})(\/|-)(0[1-9]|10|11|12)$/.test(dateString);

export const getPhoneNumberType = (number: string): TPHoneNumberType => {
  const areaCode = number.substr(0, 2);
  switch (areaCode) {
    case '02':
    case '03':
    case '07':
    case '08':
      return PhoneNumberType.Home;
    case '04':
      return PhoneNumberType.Mobile;
    default:
      return PhoneNumberType.Unknown;
  }
};

export const leadingZero = (value) => (Number(value).toString().length < 2 ? `0${Number(value)}` : `${value}`);

export const toISODate = (value) => {
  if (!value) {
    return value;
  }
  const dateSplitToArray = value.split('-');

  if (dateSplitToArray) {
    return `${dateSplitToArray[0]}-${leadingZero(dateSplitToArray[1])}-${leadingZero(dateSplitToArray[2])}`;
  } else {
    return value;
  }
};

export const hasAdultDependants = (dependants: Array<any>): boolean => {
  const today = new Date();
  for (const dependant of dependants) {
    const isoDateString = toISODate(dependant.dateOfBirth);
    const dateDob = parseISO(isoDateString);
    if (isValidDate(dateDob)) {
      const yearsDiff = Math.abs(differenceInYears(dateDob, today));
      if (yearsDiff >= MIN_ADULT_DEPENDANT_AGE && yearsDiff <= DEPENDANT_MAX_AGE) {
        return true;
      }
    }
  }
  return false;
};

export const getExtendedScale = (scale: TScale, dependants: Array<any>): TScale => {
  if (scale === Scale.ExtendedFamily) {
    console.log('*** ExtendedFamily scale passed to getExtendedScale method. This should not happen.');
    captureMessage('ExtendedFamily scale passed to getExtendedScale method. This should not happen.', 'error');
  }

  if (dependants.length === 0) return scale;

  if (scale === Scale.Single || scale === Scale.Couple) return scale;

  return hasAdultDependants(dependants) ? Scale.ExtendedFamily : scale;
};

export const getNonExtendedScale = (scale: TScale, hasPartner: TSelected): Exclude<TScale, 'ExtendedFamily'> => {
  if (scale === Scale.ExtendedFamily) {
    return hasPartner === Selected.no ? 'SingleParentFamily' : 'Family';
  }

  return scale as Exclude<TScale, 'ExtendedFamily'>;
};

export const toBool = (value: any): boolean => {
  if (typeof value === 'boolean') return value;
  if (typeof value !== 'string') return false;
  return value.toLocaleLowerCase() === 'true';
};

export const isValidISODateFormat = (dateString: string | undefined | null): boolean => {
  if (!dateString) return false;

  return /^\d{4}-\d{2}-\d{2}$/.test(dateString);
};

export const getMockCurrentDate = (): string | undefined => {
  if (process.env.GATSBY_ENVIRONMENT === 'kaos' || process.env.GATSBY_ENVIRONMENT === 'local') {
    const mockDate = Cookies.get(MOCK_CURRENT_DATE_COOKIE) || mockCurrentDateEnv;
    if (isValidISODateFormat(mockDate) && isDateStringInFuture(mockDate)) {
      return mockDate;
    }
  }
  return;
};

export const getCurrentDate = (): Date => {
  const mockedCurrentDate = getMockCurrentDate();
  return mockedCurrentDate ? new Date(mockedCurrentDate) : new Date();
};

export const createMarkup = (mainText: string, secondaryText = '') => {
  return {__html: `${mainText} ${secondaryText}`};
};
