import type {Address, GovernmentDetails, Session} from '@nib/types-session-api';
import {Selected} from '@nib/phi-constants';
import {getExtendedScale} from '../../../utils';
import {produce} from 'immer';
import {ARHIPageIndex, ARHIPageList} from '../../../constants';
import {RibbonDetailsValues} from '../../../types/pages';
import {dependantsRequired, partnerRequired} from '../../../services/utils';

export const mapRibbonDetailsToSession = (values: Partial<RibbonDetailsValues>, session: Session): Session => {
  const mappedSession = produce(session, (draftSession) => {
    if (values.scale && draftSession.personalDetails) {
      draftSession.personalDetails.hasPartner = partnerRequired(values.scale) ? Selected.yes : Selected.no;
      draftSession.personalDetails.hasDependants = dependantsRequired(values.scale) ? Selected.yes : Selected.no;

      draftSession.personalDetails.scale = getExtendedScale(values.scale, session.personalDetails.dependants);
      if (draftSession.funnelProgress && (draftSession.funnelProgress.lastCompletedPage.stepOrder || 0) > ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].stepOrder) {
        draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS];
      }
    }

    if (values.frequency && draftSession.financialDetails) draftSession.financialDetails.paymentFrequency = values.frequency;

    if (values.state) {
      const addressValues: Address = {
        state: values.state
      };

      // Connect partials to contact details object
      if (draftSession.contactDetails) {
        draftSession.contactDetails.address = addressValues;
      } else {
        draftSession.contactDetails = {
          address: addressValues
        };
      }
    }

    if (values.excess && draftSession.productSelection) {
      if (values.removeHospital) {
        draftSession.productSelection.hospital = {
          excess: values.excess,
          id: null,
          name: null,
          shortName: null
        };
      } else {
        draftSession.productSelection.hospital.excess = values.excess;
      }
    }

    if (values.incomeTier) {
      if (draftSession.governmentDetails) {
        draftSession.governmentDetails.incomeTier = values.incomeTier;
      } else {
        draftSession.governmentDetails = {
          incomeTier: values.incomeTier
        } as GovernmentDetails;
      }
    }
  });

  return mappedSession;
};
