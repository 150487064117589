import type {Session} from '@nib/types-session-api';

import {JoinResult} from './join/joinSlice';
import {OfferResult, PromoOfferResult} from './offer/types';
import {PriceResult} from './pricing/pricingSlice';
import {Features} from './featureToggles/types';
import {BatchPriceResults} from './pricing/types';
import {FetchQuoteResponse} from './resumeQuote/types';
export interface Action<TPayload = unknown> {
  type: string;
  payload: TPayload;
}

export type ResponseType = 'AxiosError' | 'Session' | 'Price' | 'Join' | 'Offer' | 'OfferV3' | 'Features';
export type HttpMethod = 'GET' | 'PUT' | 'POST';
export interface AxiosError {
  message?: string;
  request: any;
  response: any;
  isAxiosError: boolean;
  method: HttpMethod;
}

// Able to type guard against the response object
export interface AxiosResponse<T> {
  type: ResponseType;
  data: T;
}

export interface FailedRequestPayload {
  error?: AxiosError;
}

export interface SessionAndIsPageComplete {
  session: Session;
  isPageComplete?: boolean;
}

export interface PricingApiErrorResponse {
  data: {
    data: any;
    errors: {
      message?: string;
      property?: any;
    }[];
  };
}

// We could have used `AxiosResponse<any>` here but we can be specific about
// what types we are expecting across all Axios calls
type AxiosResponseSuccess =
  | AxiosResponse<Session>
  | AxiosResponse<JoinResult>
  | AxiosResponse<PriceResult>
  | AxiosResponse<BatchPriceResults>
  | AxiosResponse<OfferResult>
  | AxiosResponse<PromoOfferResult>
  | AxiosResponse<Features>
  | AxiosResponse<FetchQuoteResponse>;

// If clearly defined as an axios error or the axios flag is on the axios error object - return type as AxiosError
export const isAxiosError = (axiosResponse: AxiosResponseSuccess | AxiosResponse<AxiosError>): axiosResponse is AxiosResponse<AxiosError> => {
  const responseType: ResponseType = axiosResponse.type;
  return responseType === 'AxiosError' && (axiosResponse as AxiosResponse<AxiosError>).data.isAxiosError !== undefined;
};

export interface AttributionDetails {
  sourceCode: string;
  campaignChannel: string;
}
