import {combineReducers} from '@reduxjs/toolkit';
import sessionReducer from './session/sessionSlice';
import previousFundsReducer from './previousFunds/previousFundsSlice';
import joinReducer from './join/joinSlice';
import offerReducer from './offer/offerSlice';
import offerReducerV3 from './offerV3/offerSlice';
import pricingReducer from './pricing/pricingSlice';
import featureTogglesReducer from './featureToggles/featureToggleSlice';
import sendQuoteReducer from './sendQuote/sendQuoteSlice';
import resumeQuoteReducer from './resumeQuote/resumeQuoteSlice';
import stepperReducer from './stepper/stepperSlice';
import bsbLookupReducer from './metaLookup/bsbLookupSlice';

const rootReducer = combineReducers({
  session: sessionReducer,
  previousFunds: previousFundsReducer,
  join: joinReducer,
  offer: offerReducer,
  offerV3: offerReducerV3,
  pricing: pricingReducer,
  featureToggles: featureTogglesReducer,
  sendQuote: sendQuoteReducer,
  resumeQuote: resumeQuoteReducer,
  stepper: stepperReducer,
  bsbLookup: bsbLookupReducer
});

export type ARHIJoinState = ReturnType<typeof rootReducer>;

export default rootReducer;
