import type {Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';
import {WelcomeDetailsValues} from '../../../components/Forms/Welcome/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveWelcomeFormPendingReducer = (draftState: SessionState, action: PayloadAction<WelcomeDetailsValues>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveWelcomeFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveWelcomeFormSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  const {contactDetails, personalDetails, aboutYouDetails, productSelection, financialDetails} = action.payload;
  draftState.updatingSession = false;
  if (!draftState.session) {
    return;
  }

  draftState.session.personalDetails.policyHolder.dateOfBirth = personalDetails.policyHolder.dateOfBirth;
  draftState.session.personalDetails.policyHolder.firstName = personalDetails.policyHolder.firstName;
  draftState.session.contactDetails.phoneNumbers = contactDetails.phoneNumbers;
  draftState.session.personalDetails.policyHolder.email = personalDetails.policyHolder.email;
  draftState.session.personalDetails.scale = personalDetails.scale;
  draftState.session.personalDetails.hasDependants = action.payload.personalDetails.hasDependants;
  draftState.session.personalDetails.hasPartner = action.payload.personalDetails.hasPartner;

  if (personalDetails.partner) {
    draftState.session.personalDetails.partner = personalDetails.partner;
    // If dob is empty it be should be defaulted so that pricing observer can watch values correctly.
    draftState.session.personalDetails.partner.dateOfBirth = personalDetails.partner?.dateOfBirth || null;
  }

  if (draftState.session.aboutYouDetails && aboutYouDetails) {
    draftState.session.aboutYouDetails.hasCompletedAboutYou = aboutYouDetails.hasCompletedAboutYou;
    draftState.session.aboutYouDetails.allowLeadsContact = aboutYouDetails.allowLeadsContact;
  }

  if (draftState.session.contactDetails.address) {
    draftState.session.contactDetails.address.state = contactDetails.address?.state;
  } else {
    draftState.session.contactDetails.address = {
      state: contactDetails.address?.state
    };
  }

  draftState.session.productSelection.hospital.excess = productSelection.hospital.excess;

  draftState.session.financialDetails.coverStartDate.date = financialDetails.coverStartDate.date;

  // We only care about visitor type on the aboutYouDetails contract
  if (aboutYouDetails && draftState.session.aboutYouDetails) {
    draftState.session.aboutYouDetails.visitorType = aboutYouDetails.visitorType;
    draftState.session.aboutYouDetails.allowLeadsContact = aboutYouDetails.allowLeadsContact;
  }
};

export const completeAboutYouReducer = (draftState: SessionState) => {
  if (!draftState.session) {
    return;
  }
  if (draftState.session.aboutYouDetails) {
    draftState.session.aboutYouDetails.hasCompletedAboutYou = true;
  }
};
