import type {Session, GovernmentRebateAcknowledgement, Nullable} from '@nib/types-session-api';

import {IncomeTier, MedicareCardType} from '@nib/phi-constants';
import {produce} from 'immer';
import {GovernmentRebateValues} from '../../../components/Forms/AustralianGovernmentRebate/types';
import {formatDateToISO, isIsoDateStringFormat} from '../../../utils';
import {SelectedToNullableBoolean} from '../../../components/Forms/utils';
import {format} from 'date-fns';
import {ARHIPageList, ARHIPageIndex, ISO_DATE_FORMAT} from '../../../constants';
import {isBoolean} from '../../../services/utils';

export const mapGovernmentRebateAcknowledgementToSession = (values: GovernmentRebateValues): GovernmentRebateAcknowledgement => ({
  areYouCoveredByThisPolicy: SelectedToNullableBoolean(values.coveredByPolicy),
  areAllPeopleOnPolicyEntitledToMedicare: SelectedToNullableBoolean(values.medicareAllCoveredRadio),
  medicareCardIsValid: values.medicareCardType !== MedicareCardType.None,
  rebateIsRequested: values.incomeTier !== IncomeTier.NoRebate,
  declarationAllDetailsCorrect: Boolean(values.declaration),
  declarationDate: format(new Date(), ISO_DATE_FORMAT)
});

export const mapApplyGovernmentRebate = (rebateAcknowledge: Nullable<GovernmentRebateAcknowledgement>) => {
  if (!rebateAcknowledge) {
    return true;
  }

  const rebateIsRequired = isBoolean(rebateAcknowledge.rebateIsRequested) ? rebateAcknowledge.rebateIsRequested : true;
  const youCovered = isBoolean(rebateAcknowledge.areYouCoveredByThisPolicy) ? rebateAcknowledge.areYouCoveredByThisPolicy : true;
  const allEntitledMedcare = isBoolean(rebateAcknowledge.areAllPeopleOnPolicyEntitledToMedicare) ? rebateAcknowledge.areAllPeopleOnPolicyEntitledToMedicare : true;
  const medicareCardIsValid = isBoolean(rebateAcknowledge.medicareCardIsValid) ? rebateAcknowledge.medicareCardIsValid : true;

  return rebateIsRequired && youCovered && allEntitledMedcare && medicareCardIsValid ? true : false;
};

export const mapRebateDetailsToSession = (values: GovernmentRebateValues, session: Session, isPageComplete?: boolean): Session =>
  produce(session, (draftSession) => {
    if (draftSession.governmentDetails) {
      draftSession.governmentDetails.governmentRebateAcknowledgement = mapGovernmentRebateAcknowledgementToSession(values);
      draftSession.governmentDetails.applyGovernmentRebate = mapApplyGovernmentRebate(draftSession.governmentDetails.governmentRebateAcknowledgement);
      draftSession.governmentDetails.incomeTier = values.incomeTier;
      draftSession.governmentDetails.medicareNumber = values.medicareNumber;
      draftSession.governmentDetails.medicareCardType = values.medicareCardType;

      // with the way auto save works, this might already be in iso format due to a use case where our formik pre save helper is grabbing the "initial value"
      // from formik (which in the container already maps from an iso format to work with the component)
      draftSession.governmentDetails.medicareExpiry = values.medicareExpiry && (isIsoDateStringFormat(values.medicareExpiry) ? values.medicareExpiry : formatDateToISO(values.medicareExpiry));

      if (values.medicareCardType === MedicareCardType.None) {
        draftSession.governmentDetails.medicareNumber = null;
        draftSession.governmentDetails.medicareExpiry = null;
        draftSession.governmentDetails.governmentRebateAcknowledgement = null;
      }
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.AUSTRALIAN_GOVERNMENT_REBATE];
    }
  });
