import axios from 'axios';
import {stringify} from 'qs';
import {CampaignUrl} from '../../constants';
import {AxiosResponse, AxiosError} from '../types';
import {OfferResult, OfferParams} from './types';
import {ValidJoinServicesRequest, createAxiosError} from '../utils';

/**
 * Fetch the offer given offer params
 * @param   {object} parameters
 * @returns {Promise}
 */
export const fetch = (offerParams: OfferParams) => {
  return axios
    .get<OfferResult>(`${CampaignUrl('v1')}?${stringify(offerParams, {skipNulls: true})}`, {
      validateStatus: ValidJoinServicesRequest
    })
    .then((res) => {
      const response: AxiosResponse<OfferResult> = {
        data: res.data,
        type: 'Offer'
      };
      return response;
    })
    .catch((error) => {
      const response: AxiosResponse<AxiosError> = {
        type: 'AxiosError',
        data: createAxiosError(error, 'GET')
      };
      return response;
    });
};
